import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import App from "./App";
// import Choice from "./Choice";
const tagManagerArgs = {
	gtmId: "GTM-WT9XCBG",
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById("root");
ReactDOM.render(
	<StrictMode>
		<App />

		{/* <Choice /> */}
	</StrictMode>,
	rootElement
);
