import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import L from "leaflet";

import icon from "../constants";

export default function LeafletControlGeocoder({
	handleGeoCoderDrag,
	currentPositionCallback,
	address,
	// autoLocation
}) {
	const map = useMap();
	const [geoPos, setGeoPos] = useState(null);
	const [typedAddress, settypedAddress] = useState(null);

	useEffect(() => {
		console.log("running typed Address", typedAddress);
		typedAddress && console.log("typed name", typedAddress.name);
		// handleGeoCoderDrag(geoPos);
		//currentPositionCallback(geoPos, typedAddress);
	}, [typedAddress]);

	useEffect(() => {
		console.log("running handleGeoCoder Drag: geo pos", geoPos);
		handleGeoCoderDrag(geoPos);
		currentPositionCallback(geoPos, typedAddress);
	}, [geoPos]);

	useEffect(() => {
		var geocoder = L.Control.Geocoder.nominatim();
		if (typeof URLSearchParams !== "undefined" && location.search) {
			// parse /?geocoder=nominatim from URL
			var params = new URLSearchParams(location.search);
			var geocoderString = params.get("geocoder");
			if (geocoderString && L.Control.Geocoder[geocoderString]) {
				geocoder = L.Control.Geocoder[geocoderString]();
			} else if (geocoderString) {
				console.warn("Unsupported geocoder", geocoderString);
			}
		}

		// var search = L.Control.Geocoder.search({
		// 	inputTag: "address",
		// 	placeholder: "ex. LAX",
		// }).addTo(map);

		// L.Control.Geocoder({
		// 	inputTag: "address",
		// 	placeholder: "ex. LAX",
		// });

		// search.on("results", function (e) {
		// 	document.getElementById("map").style.display = "block";
		// 	document.getElementById("intro").style.display = "none";

		// 	// http://leafletjs.com/reference.html#map-invalidatesize
		// 	map.invalidateSize();
		// });

		L.Control.geocoder({
			container: "findbox",
			inputTag: "address",
			style: "bar",
			// initial: false,
			collapsed: false,
			query: "",
			placeholder: "Enter your Address...",
			defaultMarkGeocode: false,
			geocoder,
		})
			.on("markgeocode", function (e) {
				console.log("Did i click?", e.geocode, "and address", address);

				settypedAddress(e.geocode);

				let { lat, lng } = e.geocode.center;
				let latlng = e.geocode.center;
				let positionObject = [lat, lng];
				setGeoPos(positionObject);
				L.marker(latlng, { icon, draggable: true })
					.on("dragend", function (e) {
						//console.log("what a drag", e.target.getLatLng());
						//	let pos = e.target.getLatLng();
						const { lat, lng } = e.target.getLatLng();
						console.log("lat and lng", lat, lng);
						let positionObject = [lat, lng];

						setGeoPos(positionObject);
						// selectedPoint = e.target.getLatLng(); console.log(selectedPoint)
						// getRoute();
					})
					.addTo(map)
					.bindPopup(e.geocode.name)
					.openPopup();
				map.fitBounds(e.geocode.bbox);
			})

			.addTo(map);

		// // Call the getContainer routine.
		// var htmlObject = L.marker.getContainer();
		// // Get the desired parent node.
		// var a = document.getElementById("findbox");
		// function setParent(el, newParent) {
		// 	newParent.appendChild(el);
		// }
		// setParent(htmlObject, a);
	}, []);

	return null;
}
