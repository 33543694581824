import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

export default function RequestForm({
	service,
	handleFormSubmit,
	userInfo,
	typedAddress,
	fullAddress,
	serviceText,
	speciesInvolved,
	pinLocation,
}) {
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");

	const [phone, setPhone] = useState("");
	const [phone2, setPhone2] = useState("");
	const [email, setEmail] = useState("");
	const [email2, setEmail2] = useState("");
	const [summary, setSummary] = useState("");
	const [submitReady, setSubmitReady] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [usePin, setUsePin] = useState(false);

	// const [address, setAddress] = useState("");
	const [address, setAddress] = useState(
		typedAddress
			? typedAddress.split(",").slice(0, 2).join("")
			: fullAddress.address.Address
			? fullAddress.address.Address
			: ""
	);
	const [apartment, setApartment] = useState("");
	// const [city, setCity] = useState("");
	// const [zipCode, setZipCode] = useState("");

	const [city, setCity] = useState(fullAddress.address.City ? fullAddress.address.City : "");
	const [zipCode, setZipCode] = useState(fullAddress.address.Postal ? fullAddress.address.Postal : "");

	// captcha code
	const captchaRef = useRef(null);

	useEffect(() => {
		console.log("use pin falue", usePin);

		// handleGeoCoderDrag(geoPos);
		//currentPositionCallback(geoPos, typedAddress);
	}, [usePin]);

	console.log("reciept:", service, userInfo);

	const localFormSubmitHandler = async () => {
		if (email === email2 && phone === phone2) {
			setError(false);
			console.log("submitting requestForm.js", name + lastName);
			const infoObject = {
				name: name.concat(" ", lastName),
				// lastName: lastName,
				phone: phone,
				email: email,
				address,
				city,
				zipCode,
				apartment,
				summary: summary,

				// requestType: "Service Request",
			};

			const token = captchaRef.current.getValue();
			captchaRef.current.reset();
			console.log(token);

			await axios
				// .post("http://localhost:7071/api/HttpTrigger4Anyon/api/HttpTrigger4Anyon", { inputVal, token })
				// .post("https://captchaverification3anyon.azurewebsites.net/api/HttpTrigger4Anyon", { token })
				.post("https://animalcontrolcaptchaverification.azurewebsites.net/api/httptrigger4anyon", { token })
				.then((res) => {
					console.log(res.data);
					if (res.data === "human") {
						console.log("we found a human");
						name && lastName && phone && email && summary ? handleFormSubmit(infoObject) : setError(true);
					} else if (res.data === "robot") {
						console.log("we found a robot!");
						setErrorMessage("Captcha failed. Please try again");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			setErrorMessage("Email and Phone must match!");
		}
	};
	return (
		<section className="gray resultComponent standardForm">
			<h3>Request Services</h3>
			{serviceText && (
				<p>
					{serviceText} ({speciesInvolved ? speciesInvolved : "Species N/A"})
				</p>
			)}
			{}
			<p>
				Online service requests are dispatched as soon as possible, but can be impacted by the priority of the
				request as well as available staffing levels.
			</p>
			<p>
				<strong>STEP 3 of 3:</strong>
				<br />
				Share your contact information, in case we have questions about this Service.
			</p>
			<hr />
			<p>All Fields Required in this Step.</p>
			<div className="form-group">
				<input
					// required
					type="text"
					value={name}
					className="input-control"
					onChange={(e) => setName(e.target.value)}
				/>
				<label>First Name</label>
			</div>
			<div className="form-group">
				<input
					// required
					type="text"
					value={lastName}
					className="input-control"
					onChange={(e) => setLastName(e.target.value)}
				/>
				<label>Last Name</label>
			</div>
			<div className="form-group">
				<input
					// required
					type="text"
					value={phone}
					className="input-control"
					onChange={(e) => setPhone(e.target.value)}
				/>
				<label>Phone (with Area Code)</label>
			</div>
			<div className="form-group">
				<input
					// required
					type="text"
					value={phone2}
					className="input-control"
					onChange={(e) => setPhone2(e.target.value)}
				/>
				<label>Phone Verification</label>
			</div>
			<div className="form-group">
				<input
					// required
					type="text"
					value={email}
					className="input-control"
					onChange={(e) => setEmail(e.target.value)}
				/>
				<label>Email Address</label>
			</div>
			<div className="form-group">
				<input
					// required
					type="text"
					value={email2}
					className="input-control"
					onChange={(e) => setEmail2(e.target.value)}
				/>
				<label>Email Verification</label>
			</div>
			<div className="form-group">
				<label>Use Pin: (if address is non applicable.)</label>
				<div className="checkboxHolder">
					<input type="checkbox" onChange={(e) => setUsePin((usePin) => !usePin)} value={usePin} />{" "}
					{/* {fullAddress && (
						<span>
							Lat: {fullAddress.location.x} Long: {fullAddress.location.y}{" "}
						</span>
					)} */}
					<p>Pin location is {pinLocation && `Lat: ${pinLocation[0]} Long: ${pinLocation[1]} `}</p>
				</div>
				<span className="small">(These pin coordinates are what you submitted in Step 1 of this process)</span>
			</div>

			<div className="form-group"></div>
			{!usePin && (
				<>
					<div className="form-group">
						<input
							// required
							type="text"
							value={address}
							className="input-control"
							onChange={(e) => setAddress(e.target.value)}
						/>
						<label>Street Address</label>
					</div>
					<div className="form-group">
						<input
							// required
							type="text"
							value={apartment}
							className="input-control"
							onChange={(e) => setApartment(e.target.value)}
						/>
						<label>Apt./Suite (if Applicable)</label>
					</div>
					<div className="form-group">
						<input
							// required
							type="text"
							value={city}
							className="input-control"
							onChange={(e) => setCity(e.target.value)}
						/>
						<label>City or Unincorporated Community</label>
					</div>
					<div className="form-group">
						<input
							// required
							type="text"
							value={zipCode}
							className="input-control"
							onChange={(e) => setZipCode(e.target.value)}
						/>
						<label>Zip Code</label>
					</div>
				</>
			)}

			<div className="form-group">
				<textarea
					// required
					type="text"
					value={summary}
					className="input-control"
					onChange={(e) => setSummary(e.target.value)}
				/>
				<label>Summary of situation</label>
			</div>

			{error && <div className="redMessage">Please fill out all fields!</div>}
			{errorMessage && <div className="redMessage">{errorMessage}</div>}

			<div className="form-group captcha">
				<ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SITEKEY} />
			</div>

			<button onClick={localFormSubmitHandler} className="cta-btn">
				Submit Request
			</button>
			<p>
				If you would like to use a different method to contact us, click{" "}
				<a href="http://dacc.care/0ORT-Other-Ways-To-Contact-DACC" target="_blank">
					{" "}
					here.
				</a>{" "}
			</p>
		</section>
	);
}
