import React, { useState } from "react";
import { useRoutes, A, navigate } from "hookrouter";

const Lookup = ({ handleStartClick }) => {
	const [inputValue, setInputValue] = useState("");
	const [result, setResult] = useState(null);
	const [fetching, setFetching] = useState(false);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleConfirmClick = () => {
		setFetching(true);
		fetch(
			"https://prod-13.usgovtexas.logic.azure.us:443/workflows/0cb5a41e7e724a7f9c747d0351720181/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lE_2vUb4Ae5d0o8zvWs9sAIAt_np9P1mBkI7AnNbO6I",
			{
				method: "POST",
				body: JSON.stringify({ ticketId: inputValue }),
				headers: {
					"Content-Type": "application/JSON",
					"Access-Control-Allow-Origin": "*",
				},
			}
		)
			.then((response) => response.json())
			// .then((response) => console.log("response", response.json()))
			//	.then((data) => console.log("data", data["ACTIVITY_STAT"]))
			.then((data) => {
				setResult(data["ACTIVITY_STAT"]);
				setFetching(false);
			})
			.catch((error) => console.error(error));
	};

	console.log("result", result);

	// console.log("result length", result.length);
	console.log("input value", inputValue);

	return (
		<div>
			{" "}
			<section className="gray trackingComponent">
				<h2>Online Service Request Tracking</h2>
				<p>
					Enter the tracking number that you received in your email confirmation, which is formatted either as
					an Online Service Request “OSR(number)” or Online Animal Noise “OAN(number)” format.
				</p>

				{/* cases: new, canceled, completed, dispatched, working, pending */}
				{result !== null && !fetching && (
					<section
						className={`resultSection ${result === "COMPLETED" && "green"} ${
							result === "PENDING" && "green"
						} ${result === "WORKING" && "green"} ${
							(result === "NEW" && "yellow") ||
							(result === "DISPATCHED" && "yellow") ||
							(result === "CANCELLED" && "yellow")
						}`}
					>
						{(result === "NEW" || result === "DISPATCHED" || result == "PENDING" || result == "WORKING") &&
							!fetching && (
								<>
									<p>Thank you for your follow-up. The status of your submission is: {result}.</p>
									<p>
										Please return to this tool in the future to check the current status of your
										request.
									</p>
									<p>
										If you feel this requires further review or would like additional information,
										our Communication Centers are available. Please let them know that you submitted
										this request online and use your ID number for reference.
									</p>
								</>
							)}

						{result === "CANCELLED" && !fetching && (
							<>
								<p>Thank you for your follow-up. The status of your submission is: {result}.</p>
								<p>
									It has been reviewed and determined that no further action is required. This may be
									because it is not within our jurisdiction, or we have already received a similar
									submission and action has already been taken.
								</p>
								<p>
									If you feel this requires further review or would like additional information, our
									Communication Centers area available. Please let them know that you submitted this
									request online and use your ID number for reference.
								</p>
							</>
						)}

						{result === "COMPLETED" && !fetching && (
							<>
								<p>Thank you for your follow-up. The status of your submission is: {result}.</p>
								<p>
									If you feel this requires further review or would like additional information, our
									Communication Centers area available. Please let them know that you submitted this
									request online and use your ID number for reference.
								</p>
							</>
						)}

						{result === "" && !fetching && (
							<>
								<p>Thank you for your follow-up. The status of your submission is: NOT FOUND.</p>
								<p>
									It could be that you just submitted the request and are checking too soon or it has
									been determined that no further action is required. This may be because it is not
									within our jurisdiction or we have already received a similar submission and action
									has already been taken.
								</p>
								<p>
									If you feel this requires further review or would like additional information, our
									Communication Centers area available. Please let them know that you submitted this
									request online and use your ID number for reference.  
								</p>
							</>
						)}

						<p>
							{" "}
							North County Areas: <br />
							Served by the Castaic, Lancaster, or Palmdale Animal Care Centers. <br />
							(These are the areas generally north of Highway 118 across Interstate 5 and Interstate 210
							with the addition of Tujunga.) <br />
							<a href="tel:6619404191"> +1 (661) 940-4191</a>{" "}
						</p>

						<p className="center">
							South & West County Areas:
							<br />
							Served by the Agoura, Baldwin Park, Carson-Gardena, and Downey Animal Care Centers. <br />
							(These are the areas generally south and west of Highway 118 across Interstate 5 and
							Interstate 210 with the exception of Tujunga.) <br />
							<a href="tel:5629406898">+1 (562) 940-6898</a>
						</p>

						<p className="center">
							For a more detailed breakdown for North or South and West County areas supported, please
							visit{" "}
							<a target="_new" href="https://mcprod.animalcare.lacounty.gov/who-we-support">
								here.
							</a>{" "}
						</p>
					</section>
				)}

				<input
					type="text"
					value={inputValue}
					onChange={handleInputChange}
					placeholder="Enter OSR(number) or OAN(number)"
					className="large search"
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							handleConfirmClick();
						}
					}}
				/>

				{fetching || inputValue === "" ? (
					<button disabled>{inputValue === "" ? "Track Existing Request" : "Loading..."}</button>
				) : (
					<button onClick={handleConfirmClick}>Track Existing Request</button>
				)}

				<A href="/" className="standardButton secondary">
					Make a New Request
				</A>
				<div className="textLeft ">
					<span className="underline">Restrictions:</span>
					<ul className="trackerList">
						<li>
							This service is only available for requests or complaints made to the County of Los Angeles
							Department of Animal Care and Control via the Request Services Tool.
						</li>
						<li>
							Ticket IDs within this tool may be limited to those Online Service Requests and/or Online
							Animal Noise complaints made in the last 90 calendar days.
						</li>
					</ul>
				</div>
			</section>
		</div>
	);
};

export default Lookup;
