import React, { useEffect } from "react";

export default function Receipt({ userInfo, service, fullAddress, pinLocation }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	console.log("receipt user info", userInfo);
	console.log("receipt full address", fullAddress.address.LongLabel);
	console.log("receipt service", service);

	return (
		<section className="gray resultComponent standardForm receipt">
			<h3>Request Services or Report Animal Noise</h3>
			<p>
				Thank you for contacting the County of Los Angeles Department of Animal Care and Control (DACC). Our
				Communications Center is reviewing your request and may take 24-48 hours to process. You will also
				receive a confirmation email to the email entered into the Request Services tool containing the
				information that you submitted to us. Please note the ID in that email, which can be used for future
				tracking.
			</p>
			<p>If additional information is required, someone from our office will contact you about your request.</p>
			{/* <p>
				<b>
					If you need to follow-up, please reference the “Ticket
					Number” in your receipt.{" "}
				</b>
			</p> */}
			<h4>Field Service Requestor Information</h4>
			{userInfo.name && (
				<ul>
					<li>
						<b>Name:</b>
						<span>{userInfo.name && userInfo.name}</span>
					</li>

					{/* <li>
					<b>Address:</b>
					<span>{userInfo.address && userInfo.address}</span>
				</li> */}
					<li>
						<b>Phone:</b>
						<span>{userInfo.phone && userInfo.phone}</span>
					</li>
					<li>
						<b>Email:</b>
						<span>{userInfo.email && userInfo.email} </span>
					</li>
				</ul>
			)}{" "}
			<ul>
				{userInfo.address && (
					<>
						<li>
							<b>Street Address:</b>
							<span>
								{userInfo.address && userInfo.address}{" "}
								{userInfo.apartment && `. Unit/Number: ${userInfo.apartment}`}
							</span>
						</li>
						<li>
							<b>City:</b>
							<span>{userInfo.city && userInfo.city}</span>
						</li>
						<li>
							<b>Zip:</b>
							<span>{userInfo.zipCode && userInfo.zipCode}</span>
						</li>
					</>
				)}
				{userInfo.phone && (
					<li>
						<b>Phone:</b>
						<span>{userInfo.phone}</span>
					</li>
				)}
				{userInfo.email && (
					<li>
						<b>Email:</b>
						<span>{userInfo.email}</span>
					</li>
				)}
			</ul>
			<h4>Service Requested</h4>
			{service && service}
			{/* for regular form  ? maybe not used */}
			<ul className="lower">
				<li>
					<b>Location for Service Request</b>
					<br />

					<span>
						Lat: {pinLocation[0]}, Long: {pinLocation[1]}
						{/* Lat: {fullAddress.location.y}, Long: {fullAddress.location.x} */}
						{/* {fullAddress.address &&
						
								fullAddress.address.LongLabel} */}
					</span>
					<br />
					<br />
					<b>Calculated address based on coordinates (above)</b>
					<br />
					<span>
						{fullAddress.address.LongLabel && fullAddress.address.LongLabel}
						{/* {fullAddress.address &&
								fullAddress.address.LongLabel} */}
					</span>
				</li>
			</ul>
			{userInfo.summary && (
				<ul className="lower">
					{/* For service animal noise AND service   */}
					<li>
						{/* <b>Location for Service Request</b>
						<br />
						<span>
							lat: {fullAddress.location.x}, long: {fullAddress.location.y}
							{/* {fullAddress.address &&
								fullAddress.address.LongLabel} */}
						{/* </span> */}
						{/* {userInfo.addressOwner && (
							<>
								<b>Address Given</b>
								<br />
								{userInfo.addressOwner && userInfo.addressOwner}{" "}
								{userInfo.apartmentOwner && `. Unit/Number: ${userInfo.apartmentOwner}`}
								<br />
								{userInfo.cityOwner && userInfo.cityOwner}
								<br />
								{userInfo.zipCodeOwner && userInfo.zipCodeOwner}
								<br />
								<br />
							</>
						)} */}
						{userInfo.requestType === "Report Animal Noise" && (
							<>
								<b>Animal Name: </b>
								{userInfo.animalName ? userInfo.animalName : "unknown"}
								<br />
								<b>Owner Name: </b>
								{userInfo.ownerName ? userInfo.ownerName : "unknown"}
								<br />

								<br />

								<>
									<b>Owner Address</b>
									<br />
									{userInfo.addressOwner ? userInfo.addressOwner : "unknown"}
									{userInfo.apartmentOwner && `. Unit/Number: ${userInfo.apartmentOwner}`}
									<br />
									{userInfo.cityOwner && userInfo.cityOwner}
									<br />
									{userInfo.zipCodeOwner && userInfo.zipCodeOwner}
									<br />
								</>
							</>
						)}
						{/* <b>Calculated Address based on Coordiantes</b>
						<br />
						<span>
							{fullAddress.address.LongLabel && fullAddress.address.LongLabel}
							{/* {fullAddress.address &&
								fullAddress.address.LongLabel} */}
						{/* </span> */}
					</li>
					{/* <li>
						<b>Animal Name</b>
						<br />
						<span>{userInfo.animalName && userInfo.animalName}</span>
					</li>
					<li>
						<b>Owner First Name</b>
						<br />
						<span>{userInfo.ownerName && userInfo.ownerName}</span>
					</li>
					<li>
						<b>Owner Last Name</b>
						<br />
						<span>{userInfo.ownerLastName && userInfo.ownerLastName}</span>
					</li> */}
					<li>
						<b>Summary of Situation</b>
						<br />
						<span>{userInfo.summary && userInfo.summary}</span>
					</li>
				</ul>
			)}
		</section>
	);
}
