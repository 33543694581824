import L from "leaflet";
import marker from "./assets/marker-icon.png";
import shadow from "./assets/marker-shadow.png";

export default L.icon({
	iconSize: [25, 41],
	iconAnchor: [10, 41],
	popupAnchor: [2, -40],
	iconUrl: marker,
	shadowUrl: shadow,
});
