import React, { useState, useEffect } from "react";

import "./css/styles.scss";

import Logo from "./assets/Logo-Animal_Care_and_Control.png";

import Address from "./flow/Address";

import ServiceSelector from "./flow/ServiceSelector";
import { localResponse } from "./api/responseAzusa.js";
import AnimalNoise from "./flow/AnimalNoise";
import AnimalNoiseForm from "./flow/AnimalNoiseForm";
import FriendlyRedirect from "./flow/FriendlyRedirect";
import RequestForm from "./flow/RequestForm";
import Receipt from "./flow/Receipt";
import Choice from "./flow/Choice";
import Lookup from "./components/Lookup";

import { useRoutes, A, navigate } from "hookrouter";
import routes from "./router";

export default function App() {
	const routeResult = useRoutes(routes);
	const isProd = true;

	const [fetching, setFetching] = useState(false);
	const [reseting, setReseting] = useState(false);

	// Link to County's API
	const apiUrl = "https://locator.lacounty.gov";

	// Link to Power Automation
	const automationURL =
		"https://prod-12.usgovtexas.logic.azure.us:443/workflows/227bd2b3ed84430c965b583cddfed29c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=476yKxl7ctx8sejPwO9Dc13YFu3AJjbbTb7bxLwq7lw";

	// for testing
	useEffect(() => {
		console.log("loading version 1");
		console.log("running local", localResponse);
		!isProd && setResponse(localResponse);
	}, []);

	const [countyData, setCountyData] = useState(null);
	const [pinLocation, setPinLocation] = useState(null);
	const [response, setResponse] = useState(null);
	const [service, setService] = useState(null);
	const [serviceText, setServiceText] = useState(null);
	const [speciesInvolved, setSpeciesInvolved] = useState(null);
	// start is 0
	// selector is 2
	const [currentStep, setCurrentStep] = useState(0);
	const [showError, setShowError] = useState(false);
	const [error, setError] = useState(null);
	const [injury, setInjury] = useState(false);
	const [userInfo, setUserInfo] = useState(null);

	const [fullAddress, setFullAddress] = useState(null);

	const [typedAddress, settypedAddress] = useState(null);

	const [sendPin, setSendPin] = useState(false);

	const GEOCODE_URL =
		"https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

	useEffect(() => {
		//!isProd && setResponse(localResponse);
		response && response.IsServedByDACC && setCurrentStep(2);
		response && !reseting && setCurrentStep(2);
	}, [response]);

	useEffect(() => {
		reseting && console.log("I am reseting. I have a full address", fullAddress, "current step", currentStep);

		if (reseting) {
			setCurrentStep(1);
			setResponse(null);
			setPinLocation(null);
			setFullAddress(null);
			settypedAddress(null);
			setReseting(false);
			handleStartOver();
		}
	}, [reseting]);

	useEffect(() => {
		console.log("check I have a full address", fullAddress, "current step", currentStep);
		if (fullAddress) {
			if (fullAddress.address.AddNum !== "") {
				console.log("check I have a number! ", fullAddress.address.AddNum);
			} else {
				console.log("check I don't seem to have a complete address", fullAddress.address.AddNum);
				// setSendPin(true)
			}
		}
	}, [currentStep]);

	const currentPositionCallback = (position, fullPosition) => {
		position && console.log("App: current position", position, fullPosition);

		if (fullPosition) {
			console.log("typed", fullPosition.name);
			settypedAddress(fullPosition.name);
		} else {
			console.log("no typed");
		}

		setError(false);

		// fullPosition && setFullAddress(fullPosition);

		//	myPosition = position;
		//setPinLocation(position);
		// this gives us a memory leak
		if (position) {
			setPinLocation(position);
			console.log("setting pin location");
			fetch(GEOCODE_URL + `${position[1]},${position[0]}`)
				.then((response) => response.json())
				// .then((data) => console.log("reverse", data))
				.then((data) => setFullAddress(data));
		}
	};

	const handleStartClick = () => {
		console.log("settings current step handle Start");
		setCurrentStep(1);
	};
	const handleTrackerClick = () => {
		console.log("settings current step handle Tracker");
		navigate("/lookup");
		setCurrentStep(10);
	};

	const submitServiceLocation = () => {
		console.log("submitting service location!");
		setFetching(true);
		//	setCurrentStep(2);
		!isProd && setResponse(localResponse);
		isProd &&
			pinLocation &&
			fetch(`${apiUrl}/api/GetShelter?lat=${pinLocation[0]}&lon=${pinLocation[1]}`)
				.then((response) => {
					if (response.ok) {
						let query = `${apiUrl}/api/GetShelter?lat=${pinLocation[0]}&lon=${pinLocation[1]}`;
						console.log("query", query);

						return response.json();
					}
					console.log("our response in error", response);
					setError(true);
					throw new Error("Something went wrong with GetShelterAPI");
				})
				.then((data) => {
					console.log("response:", data);
					setResponse(data);
					setFetching(false);
				})
				.catch((error) => {
					console.log("error", error);
					setFetching(false);
				});
	};

	const handleInjury = (data) => {
		setInjury(data);
	};

	const handleServiceSelect = (selectedService, serviceText, speciesInvolved) => {
		console.log("selected service", selectedService, speciesInvolved, "text", serviceText);
		if (!selectedService) {
			console.log("trying to go back");
			setReseting(true);
			// setCurrentStep(1);
			// setResponse(null);
			// setPinLocation(null);
			// setFullAddress(null);
		}
		setServiceText(serviceText);
		speciesInvolved && setSpeciesInvolved(speciesInvolved);

		console.log("CityHandlesNoiseComplaint", response.CityHandlesNoiseComplaint);
		console.log("CityMustAuthorizeService", response.CityMustAuthorizeService);

		if (selectedService === "AdoptAnAnimal") {
			// location.href = "https://animalcare.lacounty.gov/adopt-a-pet/";
			window.open(
				"https://dacc.care/0ORT-Go-To-Adopt-An-Animal",
				"_blank" // <- This is what makes it open in a new window.
			);
		} else if (selectedService === "RegisterAPetOrPayForAPetLicense") {
			location.href = "https://animalcare.lacounty.gov/licensing/";
			// response.ProvidesPetLicense ? (
			// 	window.open("https://dacc.care/0ORT-Go-To-Pet-License", "_blank")
			// ) : (
			// 	<>
			// 		{console.log("no pet license")}
			// 		{setCurrentStep(5)}
			// 	</>
			// );
		} else if (selectedService === "RegisteranAnimal-RelatedBusinessOrPayForABusinessLicense") {
			location.href = "https://animalcare.lacounty.gov/facility-licensing/";
			// response.ProvidesBusinessLicense ? (
			// 	window.open(
			// 		"https://dacc.care/0ORT-Go-To-Facility-License",
			// 		"_blank" // <- This is what makes it open in a new window.
			// 	)
			// ) : (
			// 	<>
			// 		{" "}
			// 		{console.log("no business license")}
			// 		{setCurrentStep(5)}
			// 	</>
			// );
		} else if (selectedService === "FindAnEvent") {
			//location.href = "https://animalcare.lacounty.gov/calendar/";
			window.open(
				"https://dacc.care/0ORT-Find-Upcoming-Event-Or-Promotion",
				"_blank" // <- This is what makes it open in a new window.
			);
		} else {
			!reseting && setCurrentStep(3);
			setService(selectedService);
		}
	};

	const handleContinue = (reselect) => {
		console.log("handling continue", reselect);
		!reseting && !reselect && setCurrentStep(2);
		reselect && setCurrentStep(4);
	};

	useEffect(() => {
		console.log("running use effect!", response);
	}, [response]);

	const handleStartOver = () => {
		setCurrentStep(1);
		setShowError(false);
		setResponse(null);
		setPinLocation(null);
		setInjury(false);
		setService(null);
		setError(null);
	};

	const handleFormSubmit = (infoObject) => {
		console.log("submitting - Form submitted from app.js", infoObject);
		console.log("based on location:", pinLocation);
		fullAddress && console.log("based on address:", fullAddress);

		if (fullAddress.address.AddNum === "") {
			console.log("NO House number found!!");
		} else {
			console.log("else. House number was found");
		}

		console.log("species involved", speciesInvolved, "and service", service, "and service text", serviceText);
		let currentTime = new Date().toLocaleString();
		console.log("current time", currentTime);
		console.log("pin to send ", fullAddress.location);

		// for email and logging.
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				// address: x/
				address: `${infoObject.address} ${infoObject.apartment && `Unit Number: ${infoObject.apartment}`}`,
				name: infoObject.name,
				phone: infoObject.phone,
				email: infoObject.email,
				requestType: serviceText,
				animalType: speciesInvolved ? speciesInvolved : "unknown",
				descriptionOfSituation: infoObject.summary,
				locationAddress: fullAddress.address.LongLabel ? fullAddress.address.LongLabel : "unknown",
				locationCityZip: fullAddress ? `${fullAddress.address.City}, ${fullAddress.address.Postal}` : "unknown",
				city: infoObject.city ? infoObject.city : "unknown",
				// latitude: fullAddress.location.y.toString(),
				// longitude: fullAddress.location.x.toString(),
				latitude: pinLocation[0].toString(),
				longitude: pinLocation[1].toString(),
				animalName: infoObject.animalName ? infoObject.animalName : "unknown",
				ownerName: infoObject.ownerName ? infoObject.ownerName : "unknown",
				addressOwner: infoObject.addressOwner
					? `${infoObject.addressOwner} ${
							infoObject.apartment && `Unit Number: ${infoObject.apartmentOwner}`
					  }`
					: "unknown",
				zipCodeOwner: infoObject.zipCodeOwner ? infoObject.zipCodeOwner : "unknown",
				cityOwner: infoObject.cityOwner ? infoObject.cityOwner : "unknown",
				zipCode: infoObject.zipCode ? infoObject.zipCode : "unknown",
				timestamp: currentTime,
			}),
		};

		console.log("requestOptions", JSON.parse(requestOptions.body));

		fetch(automationURL, requestOptions)
			.then(async (response) => {
				const isJson = response.headers.get("content-type")?.includes("application/json");
				const data = isJson && (await response.json());

				console.log("response", response);
				// console.log("response good", response.json());

				// check for error response
				if (!response.ok) {
					// get error message from body or default to response status
					const error = (data && data.message) || response.status;
					console.log("error in post", error);
					return Promise.reject(error);
				}

				// this.setState({ postId: data.id });
			})
			.catch((error) => {
				// this.setState({ errorMessage: error.toString() });
				console.error("There was an error!", error);
			});

		setUserInfo(infoObject);
		setCurrentStep(6);
	};

	if (currentStep === 10 && routeResult === null) {
		setCurrentStep(1);
	}

	console.log("main content", currentStep);
	console.log("route result", routeResult);
	return routeResult ? (
		<div className="App main-content">
			{" "}
			<img src={Logo} alt="Animal Care Service Request" className="logo mainLogo" width={"90%"} />
			{routeResult}
		</div>
	) : (
		<div className="App main-content">
			{/* <div className="dev fixed left">
				BETA 0.38
		
			</div> */}
			<img src={Logo} alt="Animal Care Service Request" className="logo mainLogo" width={"90%"} />

			{/* {currentStep !== 0 && (
				<img src={Logo} alt="Animal Care Service Request" className="logo mainLogo" width={"90%"} />
			)} */}
			{currentStep === 0 && !routeResult && (
				<>
					<Choice handleStartClick={handleStartClick} handleTrackerClick={handleTrackerClick} />
				</>
			)}
			{currentStep === 1 && (
				<Address
					currentPositionCallback={currentPositionCallback}
					pinLocation={pinLocation}
					submitServiceLocation={submitServiceLocation}
					showError={showError}
					error={error}
					fetching={fetching}
				/>
			)}
			{currentStep === 2 && !injury && (
				<ServiceSelector
					currentPositionCallback={currentPositionCallback}
					pinLocation={pinLocation}
					response={response}
					handleServiceSelect={handleServiceSelect}
					handleInjury={handleInjury}
					fullAddress={fullAddress}
					// submitServiceLocation={submitServiceLocation}
				/>
			)}
			{currentStep === 2 && injury && (
				<FriendlyRedirect
					response={response}
					handleStartOver={handleStartOver}
					service={service}
					serviceText={serviceText}
					injury={injury}
				/>
			)}
			{currentStep === 3 && (
				<>
					{service === "ReportAnimalNoise" && response && !response.CityHandlesNoiseComplaint && (
						<AnimalNoise handleContinue={handleContinue} fullAddress={setFullAddress} />
					)}
					{service === "ReportAnimalNoise" && response.CityHandlesNoiseComplaint && (
						<FriendlyRedirect
							response={response}
							handleStartOver={handleStartOver}
							service={service}
							// this one is def 2
							friendlyRedirect={2}
							serviceText={serviceText}
						/>
					)}
					{service !== "ReportAnimalNoise" &&
						service !== "FindCareCenter" &&
						service !== "AnimalBite" &&
						service !== "AnimalBite" &&
						response.CityMustAuthorizeService &&
						response.CanProvideFieldServiceRightNow === false && (
							<>
								{console.log("city most authorize service is true ")}
								<FriendlyRedirect
									response={response}
									handleStartOver={handleStartOver}
									service={service}
									friendlyRedirect={1}
									serviceText={serviceText}
								/>
							</>
						)}

					{service !== "ReportAnimalNoise" &&
						service !== "FindCareCenter" &&
						service !== "AnimalBite" &&
						service !== "AnimalBiteWild" &&
						response.CityMustAuthorizeService === false &&
						response.CanProvideFieldServiceRightNow === false && (
							<>
								{console.log("city most authorize service is true ")}
								<FriendlyRedirect
									response={response}
									handleStartOver={handleStartOver}
									service={service}
									friendlyRedirect={1}
									serviceText={serviceText}
								/>
							</>
						)}
					{service !== "ReportAnimalNoise" &&
						service !== "FindCareCenter" &&
						service !== "AnimalBite" &&
						service !== "AnimalBiteWild" &&
						service !== null &&
						response.CanProvideFieldServiceRightNow && (
							<RequestForm
								service={service}
								serviceText={serviceText}
								handleFormSubmit={handleFormSubmit}
								fullAddress={fullAddress}
								typedAddress={typedAddress}
								pinLocation={pinLocation}
								speciesInvolved={speciesInvolved}
							/>
						)}

					{(service === "AnimalBite" || service === "AnimalBiteWild") && (
						// response.PrimaryAnimalService.isCounty && (
						<FriendlyRedirect
							response={response}
							handleStartOver={handleStartOver}
							service={service}
							friendlyRedirect={1}
							serviceText={serviceText}
							injury={true}
						/>
					)}

					{service === "FindCareCenter" && (
						// response.PrimaryAnimalService.isCounty && (
						<FriendlyRedirect
							response={response}
							handleStartOver={handleStartOver}
							service={service}
							friendlyRedirect={1}
							serviceText={serviceText}
						/>
					)}
				</>
			)}
			{currentStep === 4 && (
				<>
					{service === "ReportAnimalNoise" && (
						<AnimalNoiseForm
							handleFormSubmit={handleFormSubmit}
							service={serviceText}
							fullAddress={fullAddress}
							typedAddress={typedAddress}
						/>
					)}
				</>
			)}
			{/* Friendly Redirect  */}
			{currentStep === 5 && (
				<FriendlyRedirect
					response={response}
					handleStartOver={handleStartOver}
					friendlyRedirect={1}
					serviceText={serviceText}
				/>
			)}
			{currentStep === 6 && (
				<Receipt
					userInfo={userInfo}
					service={serviceText}
					fullAddress={fullAddress}
					pinLocation={pinLocation}
				/>
			)}
			{currentStep === 10 && routeResult === null && <Lookup />}
		</div>
	);
}
