import React, { useEffect, useMemo, useState } from "react";
import Address from "../components/Address";
import { MapContainer, TileLayer, Polygon, useMap, Rectangle, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import visitorIcon from "../constants";
import { azusa2, azusa } from "../assets/Data";

export default function FriendlyRedirect({
	response,
	handleStartOver,
	service,
	injury,
	friendlyRedirect,
	serviceText,
}) {
	console.log("Our response in friendly", response, "service", service, "serviceText", serviceText, "injury", injury);

	const parsedResponse = JSON.parse(response.BoundaryJSON);
	// const jsonCoords = JSON.stringify(parsedResponse.features[0].geometry.coordinates[0]);

	// console.log("our boundry json parsed", parsedResponse);
	// console.log("our boundry json selected", parsedResponse.features[0].geometry.coordinates[0]);
	// console.log("jsonCords", jsonCoords);
	// console.log("azusa2 cords", azusa2);
	// console.log("azusa cords", azusa);

	const [boundary, setBoundary] = useState(null);

	const [coordinates, setCoordinates] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setCoordinates(parsedResponse.features[0].geometry.coordinates[0].map((row) => [row[1], row[0]]));
		// setBoundary(JSON.parse(response.BoundaryJSON.features[0].geometry.coordinates[0]));
		// setBoundary(jsonCoords);
		// setBoundary(azusa);
		// console.log("boundary", jsonCoords);
	}, []);

	// useEffect(() => {
	// 	// console.log("boundary in effect", boundary);
	// 	setCoordinates(azusa.map((row) => [row[1], row[0]]));
	// }, [boundary]);
	return (
		<div>
			<section className="gray redirectComponent">
				<h3>Request Services or Report Animal Noise</h3>
				{serviceText && <>Service requested: {serviceText}</>}
				<br />

				{/* Find a Care Center  */}
				{response &&
					!injury &&
					service === "FindCareCenter" &&
					((service === "FindCareCenter" &&
						response.IsServedByDACC === true &&
						response.PrimaryAnimalService &&
						response.PrimaryAnimalService.isCounty === true) ||
					(response.IsServedByDACC === true &&
						response.PrimaryAnimalService &&
						response.BackupAnimalService === null) ? (
						<>
							{console.log("Find a Care Center Green Banner")}
							<div className="green message">
								<h3>Your location is served by a County Animal Care Center.</h3>
								<a className="btn" href="https://animalcare.lacounty.gov/service-request/"></a>
							</div>
						</>
					) : (
						<div className="yellow message">
							{console.log("Find a Care Center Puruple Banner")}
							<p>
								The selected {friendlyRedirect === 2 ? "service" : "location"} is served by an
								organization or agency other than the County of Los Angeles Department of Animal Care &
								Control.
							</p>

							<p>See below for the information provided to the County.</p>
						</div>
					))}

				{/* Animal Noise  */}
				{service &&
					!injury &&
					response &&
					service === "ReportAnimalNoise" &&
					(response.CityHandlesNoiseComplaint === false ? (
						<>
							{console.log("Report Animal Noise Banner")}
							<div className="green message">
								<h3>Your location is handled by county fields services.</h3>
								<a className="btn" href="https://animalcare.lacounty.gov/service-request/"></a>
							</div>
						</>
					) : (
						<div className="yellow message">
							<p>
								The selected {friendlyRedirect === 2 ? "service" : "location"} is served by an
								organization or agency other than the County of Los Angeles Department of Animal Care &
								Control.
							</p>
							<p>See below for the information provided to the County.</p>
						</div>
					))}

				{/* All Other Services  */}
				{service &&
					response &&
					(!injury || !response.CanProvideFieldServiceRightNow) &&
					service !== "ReportAnimalNoise" &&
					service !== "FindCareCenter" && (
						<div className="yellow message">
							<p>
								The selected {friendlyRedirect === 2 ? "service" : "location"} is served by an
								organization or agency other than the County of Los Angeles Department of Animal Care &
								Control.
							</p>
							<p>See below for the information provided to the County.</p>
						</div>
					)}

				{serviceText === "Purchase or renew a Pet License." ||
					(serviceText === "Register an animal-related business or pay for a Business License." && (
						<div className="yellow message">
							<p>
								The selected {friendlyRedirect === 2 ? "service" : "location"} is served by an
								organization or agency other than the County of Los Angeles Department of Animal Care &
								Control.
							</p>
							<p>See below for the information provided to the County.</p>
						</div>
					))}

				{/* Address Listings  */}
				{service && !injury && response && !service === "FindCareCenter" && (
					<>
						{console.log(
							"showing Primary Field Service because 'service && !injury && response && !service === FindCareCenter'"
						)}
						<Address location={response.PrimaryFieldService} using={"Field Services Contact"} />
					</>
				)}

				{service && !injury && response && service === "FindCareCenter" && (
					<>
						{console.log("Show Listings because FindCareCenter")}
						{response.PrimaryAnimalService && (
							<Address location={response.PrimaryAnimalService} using={"Primary Animal Service"} />
						)}

						{response.BackupAnimalService && (
							<>
								<Address location={response.BackupAnimalService} using={"Backup Animal Service"} />
							</>
						)}
						{response.DogService && (
							<>
								<span className="serviceLabel">Dog Service</span>
								<Address location={response.DogService} using={"Dog Service"} />
							</>
						)}
						{response.CatService && (
							<>
								<span className="serviceLabel">Cat Service</span>
								<Address location={response.CatService} using={"Cat Service"} />
							</>
						)}
					</>
				)}
				{service &&
					(!injury || !response.CanProvideFieldServiceRightNow) &&
					response &&
					!["FindCareCenter", "ReportAnimalNoise"].includes(service) && (
						<>
							{console.log(
								"Service isn't FindCareCenter or ReportAnimalNoise- showing Primary Field Service"
							)}
							<Address location={response.PrimaryFieldService} using={"Field Services Contact"} />

							{/* This was showing Animal Service not Field Service Before  */}
							{/* {service === "FoundAStrayDomestic" ? (
							<Address location={response.PrimaryFieldService} using={"Field Services Contact"} />
						) : (
							<>
								<Address location={response.PrimaryAnimalService} using={"Field Services Contact"} />
								{response.BackupAnimalService && (
									<Address location={response.BackupAnimalService} using={"Field Services Contact"} />
								)}
							</>
						)} */}
						</>
					)}

				{/* Adding added  this for animal Noise  */}
				{service && !injury && response && service === "ReportAnimalNoise" && (
					<>
						{console.log("service is Animal Noise")}
						<Address location={response.PrimaryFieldService} using={"Field Services Contact"} />
					</>
				)}
				{!service && response && !injury && (
					<>
						{console.log("No Service and No Injury - showing Primary Animal Service ")}{" "}
						<Address location={response.PrimaryAnimalService} using={"Primary Shelter"} />
					</>
				)}
				{injury && response.CanProvideFieldServiceRightNow && (
					<>
						<div className="redBox">
							For this selection of service, the County of Los Angeles Department of Animal Care and
							Control believes you will be best served by calling one of our Communication Centers, who
							can help you immediately.
						</div>
						<p>
							The DACC Communication Center operator will triage the call and, based on the information
							given, engage appropriate support.
						</p>
						<p>
							For callers in South County: <br />
							<a href="tel:5629406898">+1 (562) 940-6898 </a>{" "}
						</p>
						<p>
							For callers in North County: <br />
							<a href="tel:6619404191">+1 (661) 940-4191 </a>
						</p>
					</>
				)}

				{coordinates.length && (
					<MapContainer
						style={{ width: 100 + "%", height: 100 + "%" }}
						bounds={coordinates}
						boundsOptions={{ padding: [1, 1] }}
						scrollWheelZoom={true}
					>
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{response.PrimaryAnimalService && (
							<Marker
								position={[
									response.PrimaryAnimalService.latitude,
									response.PrimaryAnimalService.longitude,
								]}
								icon={visitorIcon}
								popupOpen={true}
							>
								<Popup>{response.PrimaryAnimalService.name}</Popup>
							</Marker>
						)}
						<Polygon positions={coordinates} />
					</MapContainer>
				)}
				<br />
				<button onClick={handleStartOver}>Start Over</button>
				<p>
					If you feel that you have reached this page in error, use a different method to contact us{" "}
					<a href="https://animalcare.lacounty.gov/contact-us/" target="_blank">
						here.
					</a>{" "}
				</p>
			</section>
		</div>
	);
}
