import { useState, useEffect } from "react";
import Address from "../components/Address";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubService from "../components/SubService";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export default function ServiceSelector({ pinLocation, response, handleServiceSelect, handleInjury, fullAddress }) {
	// console.log("response", response);
	const [redirect, setRedirect] = useState(false);
	const [service, setService] = useState(null);
	// const [speciesInvolved, setSpeciesInvolved] = useState(null);
	const [serviceText, setServiceText] = useState(null);

	const [expanded, setExpanded] = useState(false);
	const [subExpanded, setSubExpanded] = useState(false);
	const [injury, setInjury] = useState(false);
	const [injuryCheck, setInjuryCheck] = useState(false);

	const [showInjurySelector, setShowInjurySelector] = useState(false);

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		console.log("handling close"), setOpen(false);
		setService(null);
		setServiceText(null);
	};

	const handleCancel = () => {
		setService(null);
		setServiceText(null);
		setOpen(false);
		setInjury(false);
		setInjuryCheck(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleChange = (panel) => (event, isExpanded) => {
		console.log("panel", panel);
		setExpanded(isExpanded ? panel : false);
	};

	const handleSubChange = (panel) => (event, isExpanded) => {
		console.log("subpanel", panel);
		setSubExpanded(isExpanded ? panel : false);
	};

	const handleThirdChange = (panel) => (event, isExpanded) => {
		setThirdExpanded(isExpanded ? panel : false);
	};

	// const handleAccordion = (e) => {
	// 	console.log("changed!", e.target);
	// };

	useEffect(() => {
		setInjury(false);
		console.log("setting service!", service);

		const servicesRequiringInjuryCheck = [
			"FoundAStrayDomestic",
			"FoundAStrayNonDomestic",
			"AnimalBite",
			"AnimalBiteWild",
			"DomesticAnimalNonBiteInjury",
			"NonDomesticAnimalNonBiteInjury",
			"WildAnimalNonBiteInjury",
			"HouseholdDomesticAggressiveInjuredDistressedNoDanger",
			"NonHouseholdDomesticAggressiveInjuredDistressedNoDanger",
			"WildlifeAggressiveInjuredDistressedNoDanger",
			"SurrenderHouseholdDomestic",
			"SurrenderHouseholdNonDomestic",
			"AnimalLoose",
			"AnimalSick",
			"WildlifeLoose",
			"LivestockLoose",
		];

		if (servicesRequiringInjuryCheck.includes(service) && response.CanProvideFieldServiceRightNow) {
			setInjuryCheck(true);
			setOpen(true);
		} else if (service === "DeceasedAnimal" && response.CanProvideFieldServiceRightNow) {
			handleServiceSelect(service, serviceText);
		} else {
			console.log("handling service select", service);
			service && handleServiceSelect(service, serviceText);
		}

		console.log("service", service, "injuryCheck ", injuryCheck);
		// service && !injuryCheck && (service, serviceText);
	}, [service]);

	// useEffect(() => {

	// }, [injuryCheck]);

	const handleMyClick = (e) => {
		console.log("click!", e.target.innerText);
	};

	const SubServices = [
		{ title: "Animal Bite", id: "AnimalBite" },
		{
			title: "Aggressive/vicious animal (loose / at large)",
			id: "AnimalLoose",
		},
	];

	console.log("Sub Services", SubServices);

	/// here we will either friendly redirect or allow section
	return (
		<div>
			<section className="gray resultComponent">
				<h3>Request Services or Report Animal Noise</h3>
				{/* Location {pinLocation && pinLocation} */}
				{redirect && (
					<>
						<div className="yellow">
							<p>
								The selected location is served by an organization or agency other than the County of
								Los Angeles Department of Animal Care & Control.
							</p>
							<p>See below for the information provided to the County.</p>
						</div>
						{response && <PrimaryShelter response={response} />}
					</>
				)}
				{!redirect && (
					<>
						<p>We have identified your location in our system.</p>
						<p>Pin location is {pinLocation && `Lat: ${pinLocation[0]} Long: ${pinLocation[1]} `}</p>
						{fullAddress && fullAddress.address && (
							<p>Calculated Address: {fullAddress.address.LongLabel} </p>
						)}
						<p>Jurisdiction: {response && response.City}</p>

						<p>
							<button onClick={() => handleServiceSelect(null)}>Change Service Location</button>
						</p>

						<div>
							<strong>STEP 2 of 3: </strong>

							<br />
							<p>Specify what Service you need at this address and highlight to continue.</p>
						</div>
						<Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
								onClick={(e) => {
									setService("AdoptAnAnimal");
									setServiceText(e.target.innerText);
								}}
							>
								<Typography>Adopt an animal.</Typography>
							</AccordionSummary>
							{/* <AccordionDetails>
								<Typography>
									Nulla facilisi. Phasellus sollicitudin nulla
									et quam mattis feugiat. Aliquam eget maximus
									est, id dignissim quam.
								</Typography>
							</AccordionDetails> */}
						</Accordion>
						{/* <Accordion
							expanded={expanded === "panelnoise"}
							onChange={handleChange("panelnoise")}
						>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2bh-content"
								id="panel2bh-header"
								onClick={(e) => {
									setService(e.target.innerText);
									setServiceText(e.target.innerText);
								}}
							>
								<Typography>Report Animal Noise</Typography>
							</AccordionSummary>
						</Accordion> */}
						<Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2bh-content"
								id="panel2bh-header"
								onClick={(e) => {
									setService("FindCareCenter");
									setServiceText(e.target.innerText);
								}}
							>
								<Typography>Find a Care Center for Lost/Found pets.</Typography>
							</AccordionSummary>
						</Accordion>
						<Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel3bh-content"
								id="panel3bh-header"
								onClick={(e) => {
									setService("FindAnEvent");
									setServiceText(e.target.innerText);
								}}
							>
								<Typography>Find an upcoming event or promotion.</Typography>
							</AccordionSummary>
						</Accordion>
						<Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel4bh-content"
								id="panel4bh-header"
								// onClick={(e) => setService(e.target.innerText)}
							>
								<Typography>Get Services at My Location.</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{/* {SubServices.map((service, index) => {
									<SubService
										key={index}
										title={service.title}
										id={service.id}
										index={index}
									/>;
								})} */}

								{/* <Accordion
									expanded={subExpanded === "subpanel1"}
									onChange={handleSubChange("subpanel1")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel2bh-content"
										id="subpanel2bh-header"
										onClick={(e) =>
											setService(e.target.innerText)
										}
									>
										<Typography>
											Report Animal Noise
										</Typography>
									</AccordionSummary>
								</Accordion> */}

								{/* Start Services selector */}

								<Accordion
									expanded={subExpanded === "subpanel0"}
									onChange={handleSubChange("subpanel0")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel2bh-content"
										id="subpanel2bh-header"
										onClick={(e) => {
											setService("ReportAnimalNoise");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>Report Animal Noise.</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel1"}
									onChange={handleSubChange("subpanel1")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel2bh-content"
										id="subpanel2bh-header"
										onClick={(e) => {
											setService("FoundAStrayDomestic");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Found a stray household Domestic Animal and this animal is safely in my
											possession.
										</Typography>
									</AccordionSummary>
								</Accordion>
								<Accordion
									expanded={subExpanded === "subpanel2"}
									onChange={handleSubChange("subpanel2")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel2bh-content"
										id="subpanel2bh-header"
										onClick={(e) => {
											setService("FoundAStrayNonDomestic");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Found a stray non-household Domestic Animal and this animal is safely in my
											possession.
										</Typography>
									</AccordionSummary>
								</Accordion>
								<Accordion
									expanded={subExpanded === "subpanel3"}
									onChange={handleSubChange("subpanel3")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("AnimalBite");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a Domestic Animal that has bitten and injured a Person.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel4"}
									onChange={handleSubChange("subpanel4")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("AnimalBiteWild");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a Wildlife Animal that has bitten and injured a Person.
										</Typography>
									</AccordionSummary>
								</Accordion>
								<Accordion
									expanded={subExpanded === "subpanel5"}
									onChange={handleSubChange("subpanel5")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("DomesticAnimalNonBiteInjury");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a household Domestic Animal that has caused an injury (not
											bite-related) to a Person.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel6"}
									onChange={handleSubChange("subpanel6")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("NonDomesticAnimalNonBiteInjury");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a non-household Domestic Animal (like Horse or other Livestock)
											that has caused an injury (not bite-related) to a Person.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel7"}
									onChange={handleSubChange("subpanel7")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("WildAnimalNonBiteInjury");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a Wildlife Animal that has caused an injury (not bite-related) to
											a Person.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel8"}
									onChange={handleSubChange("subpanel8")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("HouseholdDomesticAggressiveInjuredDistressedNoDanger");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a household Domestic Animal which I am responsible for that is
											aggressive, injured, or in visible distress where no people are in danger.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel9"}
									onChange={handleSubChange("subpanel9")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("NonHouseholdDomesticAggressiveInjuredDistressedNoDanger");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a non-household Domestic Animal which I am responsible for that is
											aggressive, injured, or in visible distress where no people are in danger.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel10"}
									onChange={handleSubChange("subpanel10")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel3bh-content"
										id="subpanel3bh-header"
										onClick={(e) => {
											setService("WildlifeAggressiveInjuredDistressedNoDanger");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Help with a Wildlife Animal that is aggressive, injured, or in visible
											distress where no people are in danger.
										</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel11"}
									onChange={handleSubChange("subpanel11")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel4bh-header"
										id="subpanel4bh-header"
										onClick={(e) => {
											setService("DeceasedAnimal");
											setServiceText(e.target.innerText);
											setInjuryCheck(false);
										}}
									>
										<Typography>Help with a Deceased Animal.</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel12"}
									onChange={handleSubChange("subpanel12")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel5bh-header"
										id="subpanel5bh-header"
										onClick={(e) => {
											setService("SurrenderHouseholdDomestic");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>Surrender household Domestic Animal in my possession.</Typography>
									</AccordionSummary>
								</Accordion>

								<Accordion
									expanded={subExpanded === "subpanel13"}
									onChange={handleSubChange("subpanel13")}
								>
									<AccordionSummary
										// expandIcon={<ExpandMoreIcon />}
										aria-controls="subpanel6bh-header"
										id="subpanel6bh-header"
										onClick={(e) => {
											setService("SurrenderHouseholdNonDomestic");
											setServiceText(e.target.innerText);
										}}
									>
										<Typography>
											Surrender household Non-Domestic Animal in my possession.
										</Typography>
									</AccordionSummary>
								</Accordion>

								{/* End Sub Panel  */}
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel7bh-content"
								id="panel7bh-header"
								onClick={(e) => {
									setService("RegisterAPetOrPayForAPetLicense");
									setServiceText(e.target.innerText);
								}}
							>
								<Typography>Purchase or renew a Pet License.</Typography>
							</AccordionSummary>
						</Accordion>
						<Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel8bh-content"
								id="panel8bh-header"
								onClick={(e) => {
									setService("RegisteranAnimal-RelatedBusinessOrPayForABusinessLicense");
									setServiceText(e.target.innerText);
								}}
							>
								<Typography>
									Register an animal-related business or pay for a Business License.
								</Typography>
							</AccordionSummary>
						</Accordion>

						{/* <Accordion
							expanded={expanded === "panel6"}
							onChange={handleChange("panel6")}
						>
							<AccordionSummary
								// expandIcon={<ExpandMoreIcon />}
								aria-controls="panel6bh-content"
								id="panel6bh-header"
								onClick={(e) => setService("Find an event")}
							>
								<Typography>
									Find an upcoming event or promotion
								</Typography>
							</AccordionSummary>
						</Accordion> */}
						{/* <p>
							<button
								onClick={() =>
									handleServiceSelect(service, serviceText)
								}
								disabled={!service}
							>
								Choose Service
							</button>
						</p> */}
						<p>
							If more than one Service is needed, use a different method to contact us{" "}
							<a href="http://dacc.care/0ORT-Other-Ways-To-Contact-DACC" target="_blank">
								here.
							</a>
						</p>
					</>
				)}
			</section>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{serviceText ? serviceText : service}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
						<p>Please select one option below:</p>
						<div className="button-wrapper">
							{!injury && injuryCheck && (
								<>
									{/* <button onClick={() => handleInjury(true)}>
								
								</button>{" "} */}
									{(service === "FoundAStrayDomestic" ||
										service === "AnimalBite" ||
										service === "DomesticAnimalNonBiteInjury" ||
										service === "HouseholdDomesticAggressiveInjuredDistressedNoDanger" ||
										service === "SurrenderHouseholdDomestic") && (
										<>
											<button
												onClick={() => handleServiceSelect(service, serviceText, "ConfinedCat")}
												className="gray"
											>
												Cat (Safely Confined)
											</button>{" "}
											<button
												onClick={() => handleServiceSelect(service, serviceText, "RoamingCat")}
												className="gray"
											>
												Cat (Roaming at Large)
											</button>{" "}
											<button
												onClick={() => handleServiceSelect(service, serviceText, "ConfinedDog")}
												className="gray"
											>
												Dog (Safely Confined){" "}
											</button>
											<button
												onClick={() => handleServiceSelect(service, serviceText, "RoamingDog")}
												className="gray"
											>
												Dog (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedRabbit")
												}
												className="gray"
											>
												Rabbit (Safely Confined)
											</button>
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingRabbit")
												}
												className="gray"
											>
												Rabbit (Roaming At Large)
											</button>{" "}
										</>
									)}
									{(service === "FoundAStrayNonDomestic" ||
										service === "NonDomesticAnimalNonBiteInjury" ||
										service === "NonHouseholdDomesticAggressiveInjuredDistressedNoDanger" ||
										service === "SurrenderHouseholdNonDomestic") && (
										<>
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedCattle")
												}
												className="gray"
											>
												Cattle (Safely Confined)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingCattle")
												}
												className="gray"
											>
												Cattle (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedGoat")
												}
												className="gray"
											>
												Goat / Sheep (Safely Confined)
											</button>
											<button
												onClick={() => handleServiceSelect(service, serviceText, "RoamingGoat")}
												className="gray"
											>
												Goat / Sheep (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedHorse")
												}
												className="gray"
											>
												Horse (Safely Confined)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingHorse")
												}
												className="gray"
											>
												Horse (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedSwine")
												}
												className="gray"
											>
												Swine / Pig (Safely Confined)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingSwine")
												}
												className="gray"
											>
												Swine / Pig (Roaming At Large)
											</button>{" "}
										</>
									)}
									{(service === "AnimalBiteWild" ||
										service === "WildAnimalNonBiteInjury" ||
										service === "WildlifeAggressiveInjuredDistressedNoDanger") && (
										<>
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedBear")
												}
												className="gray"
											>
												Bear (Safely Confined)
											</button>{" "}
											<button
												onClick={() => handleServiceSelect(service, serviceText, "RoamingBear")}
												className="gray"
											>
												Bear (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedRaccoon")
												}
												className="gray"
											>
												Raccoon (Safely Confined)
											</button>
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingRacoon")
												}
												className="gray"
											>
												Raccoon (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedSnake")
												}
												className="gray"
											>
												Snake (Safely Confined)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingSnake")
												}
												className="gray"
											>
												Snake (Roaming At Large)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "ConfinedWildcat")
												}
												className="gray"
											>
												Wildcat (Safely Confined)
											</button>{" "}
											<button
												onClick={() =>
													handleServiceSelect(service, serviceText, "RoamingWildcat")
												}
												className="gray"
											>
												Wildcat (Roaming At Large)
											</button>{" "}
										</>
									)}
									<button
										onClick={() =>
											handleServiceSelect(service, serviceText, "OtherSpeciesConfined")
										}
										className="gray"
									>
										Other Species (Safely Confined)
									</button>{" "}
									<button
										onClick={() => handleServiceSelect(service, serviceText, "OtherSpeciesRoaming")}
										className="gray"
									>
										Other Species (Roaming At Large)
									</button>
								</>
							)}
						</div>
						<br />
						<button className="secondary" onClick={handleCancel}>
							Go Back
						</button>{" "}
						{injury && <>Please call 911 (or other service) Friendly Redirect 3</>}
						{!injuryCheck && (
							<>
								<p>
									<button className="cancelBtn" onClick={handleCancel}>
										Cancel
									</button>{" "}
									<button
										onClick={() => handleServiceSelect(service, serviceText)}
										disabled={!service}
									>
										Confirm
									</button>
								</p>
								<p>
									If more than one Service is needed, use a different method to contact us{" "}
									<a href="https://animalcare.lacounty.gov/contact-us/" target="_blank">
										here.
									</a>
								</p>
							</>
						)}
					</Typography>
				</Box>
			</Modal>
		</div>
	);
}
