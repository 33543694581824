import React, { useEffect } from "react";

export default function Address({ location, using }) {
	console.log("response address", location, using);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let address = encodeURIComponent(location.address1.trim());
	let city = encodeURIComponent(location.city.trim());

	let MapLink = `https://www.google.com/maps?daddr=${address}+${city}+${location.state}+${location.zip}`;
	//const data = response.PrimaryShelter;
	console.log("map link", MapLink);
	console.log("web link", location.website);
	return (
		<div className="card">
			{/* {using && using} */}
			{location && (
				<>
					<h2>{location.name}</h2>
					<p>
						<a target="_blank" href={MapLink} rel="noopener noreferrer">
							{location.address1} {location.city}, {location.zip}
						</a>
					</p>
					{location.hours && <p>{location.hours}</p>}
					<p>{location.phone}</p>
					{location.website && (
						<button className="whiteText">
							<a href={location.website} target="_blank">
								Visit Website
							</a>
						</button>
					)}
				</>
			)}
		</div>
	);
}
