import { useEffect, useState } from "react";

import Maps from "../components/Maps";

import SearchBox from "../components/SearchBox";
// import Maps from "./Maps";

// Geocoding
// import Geolookup from "../../src/Geolookup";
// IMPORTANT: nomintim-browser is only included in dev dependencies
import * as Nominatim from "nominatim-browser";

export default function Where({
	currentPositionCallback,
	pinLocation,
	submitServiceLocation,
	showError,
	error,
	fetching,
}) {
	const [yourLocation, setYourLocation] = useState("");
	const [showMap, setShowMap] = useState(false);

	const [street, setStreet] = useState("");
	const [apartment, setApartment] = useState("");
	const [city, setCity] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [submitReady, setSubmitReady] = useState(false);

	// new
	const [selectPosition, setSelectPosition] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		console.log("got an error - out of coverage area", showError);
	}, [showError]);

	useEffect(() => {
		console.log("running use effect!", street.length, apartment.length);
		console.log("pin location", pinLocation);

		if ((street.length >= 1 && city.length >= 1 && zipCode.length >= 1) || pinLocation) {
			// console.log("setting true ");
			setSubmitReady(true);
		} else {
			setSubmitReady(false);
		}
	}, [street, city, zipCode, pinLocation]);

	return (
		<>
			<section className="gray whereComponent">
				<h3>Request Services or Report Animal Noise</h3>
				<p>As you complete this form, we will interactively help address your animal-related concern. </p>
				<p>
					<strong>STEP 1 of 3:</strong>
					<br />
					Show us where Service is needed. Please type in the address or drop a pin to tell us the Service
					location, which may not be the same as your physical address. Then confirm the address using the
					button and the County of Los Angeles look-up service will help pinpoint your Service location.
				</p>
				{showError && (
					<p className="error yellowbackground">
						Please recheck this address. This address does not appear in the County of Los Angeles database.
						Or, use a different method to contact us{" "}
						<a href="http://dacc.care/0ORT-Other-Ways-To-Contact-DACC" target="_blank">
							here.
						</a>
					</p>
				)}
				{error && (
					<p className="error yellowbackground">
						Please recheck this address. This address does not appear in the County of Los Angeles database.
						Or, use a different method to contact us{" "}
						<a href="http://dacc.care/0ORT-Other-Ways-To-Contact-DACC" target="_blank">
							here.
						</a>
					</p>
				)}
				<div className="maps-wrapper">
					<Maps
						currentPositionCallback={currentPositionCallback}
						address={street}
						// selectPosition={selectPosition}
					/>
				</div>

				{/* <SearchBox
					selectPosition={selectPosition}
					setSelectPosition={setSelectPosition}
				/> */}

				{/* <p>Or Continue with keyboard input below</p> */}
				{/* <p>
					The County of Los Angeles offers this look-up service to help us pinpoint your location. Please
					share your device location to help it operate.
				</p> */}

				{/* <p>All Fields Required in this Step</p> */}

				<div className="locationWrapper">
					{pinLocation ? (
						<div>
							Lat: {pinLocation[0]}
							<br /> Long: {pinLocation[1]}
						</div>
					) : (
						"No Location Entered Yet."
					)}
				</div>

				<button
					className="submit-location-btn"
					onClick={submitServiceLocation}
					disabled={!submitReady || error || fetching}
				>
					{showError && !fetching && "Proceed Anyways"}
					{!showError && fetching && "Loading"}
					{!showError && !fetching && "Submit Location"}
				</button>
				<p>
					If you have a request that involves multiple addresses, please use a different method to contact us{" "}
					<a href="https://mcprod.animalcare.lacounty.gov/contact-dacc" target="_blank">
						here.
					</a>{" "}
				</p>
			</section>
		</>
	);
}
