import React, { useEffect } from "react";

export default function AnimalNoise({ handleContinue }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			{" "}
			<section className="gray noiseComponent">
				<h3>Report Animal Noise</h3>
				<div className="card green center full">
					Animal Noise Complaints <br />
					County of Los Angeles  
					<p>
						We understand that continuous noise caused by animals (e.g. dogs, roosters, etc.) can adversely
						affect quality of life, and we are dedicated to helping to resolve such situations within our
						authorized jurisdiction.
					</p>
				</div>
				<p>
					<b className="underline">Animal Noise Complaint Process</b>
				</p>{" "}
				<p>
					<strong>1. Submit a complaint </strong>by completing the online form.{" "}
				</p>
				<p>
					<strong>2. A courtesy letter will be sent</strong> to the resident (and to you) notifying both
					parties of the complaint and the need to re­solve the situation.{" "}
				</p>
				<p>
					<strong>3. Submit a signed, original affidavit (as the reporting party)</strong> via U.S. Mail to
					the address shown in the letter or deliver to any County Animal Care Center near you.
					{/* (Learn More){" "} */}
				</p>
				<p>
					<strong> 4. Officer issues Warning of Excess Noise form (WEN)</strong> once the signed affidavit is
					received.
					{/* (Learn More){" "} */}
				</p>
				<p>
					<strong>5. Ten days to reduce the noise </strong> Once the WEN has been issued to the animal owners,
					they have ten (10) days to reduce the noise.{" "}
				</p>
				<p>
					<strong> 6. Administrative Citation</strong> If the excessive noise continues beyond the ten-day
					period, the reporting party must provide the Department with evidence of the continuing excessive
					noise.
				</p>
				<p>
					Then, the Department will re-contact the responsible person and issue an administrative citation
					that results in a fine that must be paid within 14 days.
					{/* (Learn More) */}
				</p>
				<button onClick={() => handleContinue(true)}>Continue</button>
				<button onClick={() => handleContinue(false)} className="secondary marginLeft">
					Reselect Service
				</button>
			</section>
		</div>
	);
}
