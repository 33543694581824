import React, { useEffect, useRef } from "react";
// import Logo from "../assets/Logo-Animal_Care_and_Control.png";
import Doggie from "../assets/service-request-bg-1920x805.jpg";
// import ReCAPTCHA from "react-google-recaptcha";
// import axios from "axios";

// import { useRoutes, A } from "hookrouter";
// import routes from "../router";

const Choice = ({ handleStartClick, handleTrackerClick }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	console.log(process.env.REACT_APP_SITEKEY);

	return (
		<div className="App main-content">
			<header>
				{/* <h1>Animal Care Service Area Locator</h1> */}
				{/* <img src={Doggie} alt="Animal Care Service Request" className="logo" width={"100%"} />
				<div className="overlay">
					<h4>About the Department</h4>
					<h1>Service Request</h1>
				</div> */}
			</header>
			{/* <img src={Logo} alt="Animal Care Service Request" className="logo mainLogo" width={"90%"} /> */}

			<section className="gray trackingComponent">
				<h3>Request Services or Report Animal Noise</h3>
				<p>
					This request tool is meant for non-emergency animal-related situations and will guide you to the
					appropriate group within the County of Los Angeles Department of Animal Care and Control (DACC) or
					the agency that can help within the County of Los Angeles.
				</p>
				{/* <A href="/request"></A> */}

				<button onClick={handleStartClick} className="new-request-btn">
					Make a New Request
				</button>

				<button className="secondary track-request-btn" onClick={handleTrackerClick}>
					Track Existing Request
				</button>
			</section>
			<section className="gray trackingComponent">
				<p>
					If you consider this situation to be a human or animal emergency, please call one of our
					Communication Centers.
				</p>
				<p>
					{" "}
					<b> North County Areas: </b>
					<br />
					Served by the Castaic, Lancaster, or Palmdale Animal Care Centers. <br />
					(These are the areas generally north of Highway 118 across Interstate 5 and Interstate 210 with the
					addition of Tujunga.)
					<br />
					<a href="tel:6619404191"> +1 (661) 940-4191</a>{" "}
				</p>

				<p className="center">
					<b> South & West County Areas: </b>
					<br />
					Served by the Agoura, Baldwin Park, Carson-Gardena, and Downey Animal Care Centers.
					<br /> (These are the areas generally south and west of Highway 118 across Interstate 5 and
					Interstate 210 with the exception of Tujunga.)
					<br />
					<a href="tel:5629406898">+1 (562) 940-6898</a>
				</p>
				<p>
					For a more detailed breakdown for North or South and West County areas supported, please visit{" "}
					<a href="https://dacc.care/0ORT-Who-We-Support" target="_blank">
						who we support.
					</a>
				</p>
			</section>
			<section className="yellow trackingComponent alignLeft">
				<h2>About This Form</h2>
				<p>
					Online service requests are dispatched as soon as possible. Field response time varies depending on
					priority and staffing levels.
				</p>
				<p>
					Please note that your request is a public record. For more information about the DACC
					Confidentiality Policy, please go to{" "}
					<a href="https://dacc.care/0ORT-Tool-Feedback" target="_blank">
						feedback
					</a>
					, where you can also comment on this tool.
				</p>
			</section>
		</div>
	);
};

export default Choice;
