import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

export default function AnimalNoiseForm({ handleFormSubmit, service, fullAddress, typedAddress }) {
	const [addressOwner, setAddressOwner] = useState(
		typedAddress
			? typedAddress.split(",").slice(0, 2).join("")
			: fullAddress.address.Address
			? fullAddress.address.Address
			: ""
	);
	const [apartmentOwner, setApartmentOwner] = useState("");
	const [cityOwner, setCityOwner] = useState(fullAddress.address.City ? fullAddress.address.City : "");
	const [zipCodeOwner, setZipCodeOwner] = useState(fullAddress.address.Postal ? fullAddress.address.Postal : "");

	const [address, setAddress] = useState("");
	const [apartment, setApartment] = useState("");
	const [city, setCity] = useState("");
	const [zipCode, setZipCode] = useState("");

	const [summary, setSummary] = useState("");
	const [animalName, setAnimalName] = useState("");

	const [ownerName, setOwnerName] = useState("");
	const [name, setName] = useState("");
	const [ownerLastName, setOwnerLastName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [emailVerify, setEmailVerify] = useState("");

	const [submitReady, setSubmitReady] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	// console.log("env config", process.env);

	// captcha code
	const captchaRef = useRef(null);

	const localFormSubmitHandler = async () => {
		// useEffect(() => {
		// 	window.scrollTo(0, 0);
		// }, []);
		if (email === emailVerify) {
			console.log("submitting AnimalNoiseForm.js");
			setError(false);
			const infoObject = {
				addressOwner: addressOwner ? addressOwner : undefined,
				zipCodeOwner,
				cityOwner,
				apartmentOwner,
				ownerName: ownerName ? ownerName.concat(" ", ownerLastName) : undefined,
				name,
				address,
				zipCode,
				city,
				apartment,
				phone,
				email,
				summary,
				animalName: animalName ? animalName : undefined,
				requestType: "Report Animal Noise",
				// name: name,
				// lastName: lastName,
				// phone: phone,
				// email: email,
				// notes: notes,
			};

			// const inputVal = await e.target[0].value;
			const token = captchaRef.current.getValue();
			captchaRef.current.reset();
			// console.log(token);

			await axios
				// .post("http://localhost:7071/api/HttpTrigger4Anyon/api/HttpTrigger4Anyon", { inputVal, token })
				// .post("https://captchaverification3anyon.azurewebsites.net/api/HttpTrigger4Anyon", { token })
				.post("https://animalcontrolcaptchaverification.azurewebsites.net/api/httptrigger4anyon", { token })
				.then((res) => {
					console.log(res.data);
					if (res.data === "human") {
						console.log("we found a human");
						address && zipCode && city && phone && email
							? handleFormSubmit(infoObject, service)
							: setError(true);
					} else if (res.data === "robot") {
						console.log("we found a robot!");
						setErrorMessage("Captcha failed. Please try again");
					}
				})
				.catch((error) => {
					console.log(error);
				});

			// this will need to be moved inside of the THEN
			//
		} else {
			setErrorMessage("Email address must match!");
		}
	};
	return (
		<div>
			{" "}
			<section className="gray resultComponent standardForm">
				<h3>Report Animal Noise</h3>
				<p>
					<b>STEP 3 of 3:</b>
					<br />
					Share your contact information, in case we have questions about this Service.
				</p>

				<p>
					<strong>Animal Noise Complaint Form</strong>
				</p>
				<div className="form-group">
					<input
						// required
						type="text"
						value={animalName}
						className="input-control"
						onChange={(e) => setAnimalName(e.target.value)}
					/>
					<label>Name of Animal (if known) </label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={ownerName}
						className="input-control"
						onChange={(e) => setOwnerName(e.target.value)}
					/>
					<label>Owner First Name (if known)</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={ownerLastName}
						className="input-control"
						onChange={(e) => setOwnerLastName(e.target.value)}
					/>
					<label>Owner Last Name (if known)</label>
				</div>
				<div className="form-group">
					{fullAddress && (
						<div className="gray2 center">
							Lat: {fullAddress.location.y}, Long: {fullAddress.location.x}{" "}
						</div>
					)}
					<span className="small center">
						(These pin coordinates are what you submitted in Step 1 of this process.)
					</span>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={addressOwner}
						className="input-control"
						onChange={(e) => setAddressOwner(e.target.value)}
					/>
					<label>Owner's Street Address</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={apartmentOwner}
						className="input-control"
						onChange={(e) => setApartmentOwner(e.target.value)}
					/>
					<label>Owner's Apt./Suite (if Applicable)</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={cityOwner}
						className="input-control"
						onChange={(e) => setCityOwner(e.target.value)}
					/>
					<label>Owner's City or Unincorporated Community</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={zipCodeOwner}
						className="input-control"
						onChange={(e) => setZipCodeOwner(e.target.value)}
					/>
					<label>Owner's Zip Code</label>
				</div>

				<div className="form-group">
					<textarea
						// required
						type="text"
						value={summary}
						className="input-control"
						onChange={(e) => setSummary(e.target.value)}
					/>
					<label>
						Summary of situation, including the circumstances and nature of the animal noise. For example,
						the dog barks continuously at night interrupting sleep. (Required)
					</label>
				</div>
				<p>Please provide your mailing address.</p>

				<div className="form-group">
					<input
						// required
						type="text"
						value={name}
						className="input-control"
						onChange={(e) => setName(e.target.value)}
					/>
					<label>Name</label>
				</div>

				<div className="form-group">
					<input
						// required
						type="text"
						value={address}
						className="input-control"
						onChange={(e) => setAddress(e.target.value)}
					/>
					<label>Street Address</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={apartment}
						className="input-control"
						onChange={(e) => setApartment(e.target.value)}
					/>
					<label>Apt./Suite (if Applicable)</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={city}
						className="input-control"
						onChange={(e) => setCity(e.target.value)}
					/>
					<label>City or Unincorporated Community</label>
				</div>
				<div className="form-group">
					<input
						// required
						type="text"
						value={zipCode}
						className="input-control"
						onChange={(e) => setZipCode(e.target.value)}
					/>
					<label>Zip Code</label>
				</div>

				<div className="form-group">
					<input
						// required
						type="text"
						value={phone}
						className="input-control"
						onChange={(e) => setPhone(e.target.value)}
					/>
					<label>Phone</label>
				</div>

				<div className="form-group">
					<input
						// required
						type="text"
						value={email}
						className="input-control"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label>Email</label>
				</div>

				<div className="form-group">
					<input
						// required
						type="text"
						value={emailVerify}
						className="input-control"
						onChange={(e) => setEmailVerify(e.target.value)}
					/>
					<label>Verify Email</label>
				</div>

				{error && <div className="redMessage">Please fill out all fields!</div>}
				{errorMessage && <div className="redMessage">{errorMessage}</div>}

				<div className="form-group captcha">
					<ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SITEKEY} />
				</div>

				<button onClick={localFormSubmitHandler}>Submit Request</button>
				<p>
					If you would like to use a different method to contact us, click{" "}
					<a href="http://dacc.care/0ORT-Other-Ways-To-Contact-DACC/" target="_blank">
						{" "}
						here.
					</a>{" "}
				</p>
			</section>
		</div>
	);
}
