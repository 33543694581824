export const localResponse = {
	GisDetail: { District: 4, Jurisdiction: "DOW-X 90240" },
	CanProvideFieldServiceRightNow: true,
	City: "City of Azusa",
	IsServedByDACC: true,
	ProvidesPetLicense: true,
	ProvidesBusinessLicense: true,
	ProvidesClinicOutreach: true,
	CityHandlesNoiseComplaint: true,
	CityMustAuthorizeService: false,
	AfterhoursBypassesCityAuthorization: false,
	AfterhoursWindowStart: null,
	AfterhoursWindowEnd: null,
	WeekendBypassesCityAuthorization: false,
	WeekendDaysOfWeek: null,
	FieldServiceContact: {
		locationID: 3173634,
		name: "Baldwin Park Animal Care Center",
		address1: "4275 N. Elton St.",
		address2: null,
		city: "Baldwin Park",
		state: "CA",
		zip: "91706",
		phone: "211 Use Only - Reporting Administrative (626) 813-6737, 24 Hr. Call Center Service/Intake (626) 962-3577",
		hours: "Monday through Thursday, 12:00pm to 7:00pm; Friday, Saturday, Sunday, 10:00am to 5:00pm. Closed holidays. ",
		email: null,
		website: "https://animalcare.lacounty.gov",
		latitude: 34.091351,
		longitude: -117.950928,
		isCounty: true,
	},
	PrimaryShelter: {
		locationID: 3173634,
		name: "Baldwin Park Animal Care Center",
		address1: "4275 N. Elton St.",
		address2: null,
		city: "Baldwin Park",
		state: "CA",
		zip: "91706",
		phone: "211 Use Only - Reporting Administrative (626) 813-6737, 24 Hr. Call Center Service/Intake (626) 962-3577",
		hours: "Monday through Thursday, 12:00pm to 7:00pm; Friday, Saturday, Sunday, 10:00am to 5:00pm. Closed holidays. ",
		email: null,
		website: "https://animalcare.lacounty.gov",
		latitude: 34.091351,
		longitude: -117.950928,
		isCounty: true,
	},
	BackupShelter: {
		locationID: 3173634,
		name: "Baldwin Park Animal Care Center",
		address1: "4275 N. Elton St.",
		address2: null,
		city: "Baldwin Park",
		state: "CA",
		zip: "91706",
		phone: "211 Use Only - Reporting Administrative (626) 813-6737, 24 Hr. Call Center Service/Intake (626) 962-3577",
		hours: "Monday through Thursday, 12:00pm to 7:00pm; Friday, Saturday, Sunday, 10:00am to 5:00pm. Closed holidays. ",
		email: null,
		website: "https://animalcare.lacounty.gov",
		latitude: 34.091351,
		longitude: -117.950928,
		isCounty: true,
	},
};
