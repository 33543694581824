import React, { useEffect, useState, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import LocationButton from "./LocationButton";

import visitorIcon from "../constants";
// import "./Maps.css";

const Maps = ({ currentPositionCallback }) => {
	const [position, setPosition] = useState(null);
	const [text, setText] = useState(null);
	const [currentPos, setCurrentPos] = useState(null);
	const [geoLocation, setGeoLocation] = useState(null);
	const [clicked, setClicked] = useState(false);
	const [autoLocation, setAutoLocation] = useState(null);
	const [didAutoPosition, setDidAutoPosition] = useState(false);
	// const Maps = () => {
	// visitor geoLocalisation on the Map

	const handleGeoCoderDrag = (data) => {
		console.log("geo drag callback", data);
		setGeoLocation(data);
		//currentPositionCallback(data);
		setPosition(null);
		setClicked(true);
		// setCurrentPos(data);
	};

	useEffect(() => {
		console.log("auto location", autoLocation);
		console.log("auto location clicked", clicked);
		console.log("auto location position", position);

		if (autoLocation && !position && !clicked && !didAutoPosition) {
			console.log("auto location RUNNING", autoLocation);
			const { lat, lng } = autoLocation;

			let positionObject = [lat, lng];
			setPosition(autoLocation);
			currentPositionCallback(positionObject);

			setDidAutoPosition(true);
		}
	}, [autoLocation, position]);

	// function ClickerMarker() {
	// 	const map = useMap();

	// 	map.on("click", function (e) {
	// 		var mp = new L.Marker([e.latlng.lat, e.latlng.lng]).addTo(map);
	// 		setPosition(e.latlng);
	// 		const { lat, lng } = e.latlng;
	// 		// console.log("lat and lng", lat, lng);
	// 		let positionObject = [lat, lng];
	// 		//setCurrentPos(positionObject);
	// 		// setPosition(positionObject);
	// 		currentPositionCallback(positionObject);
	// 	});
	// }

	function LocationMarker() {
		console.log("Location marker function in Maps firing");

		const map = useMap();

		if (!clicked) {
			map.on("click", function (e) {
				// var mp = new L.Marker([e.latlng.lat, e.latlng.lng]).addTo(map);
				setPosition(e.latlng);
				const { lat, lng } = e.latlng;
				// console.log("lat and lng", lat, lng);
				let positionObject = [lat, lng];
				//setCurrentPos(positionObject);
				// setPosition(positionObject);
				// setGeoLocation(null);
				currentPositionCallback(positionObject);
				setClicked(true);
			});
		}

		// this is firing over and over
		// useEffect(() => {
		console.log("running on load in maps", geoLocation);
		// geolocater isn't passing position to map here..
		geoLocation && console.log("effect WITH Location");
		// if (!position) {
		if (!geoLocation && !position) {
			console.log(
				" location marker function- no position foundrunning use effect map"
			);
			const legend = L.control({ position: "bottomleft" });
			map.locate().on(
				"locationfound",
				function (e, position, geoLocation) {
					console.log(
						"Location Found",
						position,
						geoLocation,
						clicked
					);
					// console.log("setting and flying to position");
					setAutoLocation(e.latlng);
					// setPosition(e.latlng);
					// const { lat, lng } = e.latlng;

					// let positionObject = [lat, lng];
					// currentPositionCallback(positionObject);

					// map.flyTo(e.latlng, map.getZoom());
				}
			);
		}
		// 	console.log(
		// 		" location marker function- no position foundrunning use effect map"
		// 	);
		// 	// const legend = L.control({ position: "bottomleft" });
		// 	// map.locate().on("locationfound", function (e) {
		// 	// 	console.log("setting and flying tp position");
		// 	// 	setPosition(e.latlng);
		// 	// 	// currentPositionCallback(e.latlng);
		// 	// 	map.flyTo(e.latlng, map.getZoom());
		// 	// });
		// 	// legend.onAdd = () => {
		// 	// 	const div = L.DomUtil.create("div", "legend");
		// 	// 	div.innerHTML = `click marker, move`;
		// 	// 	console.log("setting text");
		// 	// 	setText(div);
		// 	// 	return div;
		// 	// };
		// 	// legend.addTo(map);
		// }
		// }, []);

		const eventHandlers = useMemo(
			() => ({
				dragend(e) {
					console.log("dragging");
					// text.innerHTML = e.target.getLatLng();
					const { lat, lng } = e.target.getLatLng();
					console.log("lat and lng", lat, lng);
					let positionObject = [lat, lng];
					//setCurrentPos(positionObject);
					setPosition(positionObject);
					currentPositionCallback(positionObject);
					//setGeoLocation(positionObject);
				},
			}),
			[text]
		);

		useEffect(() => {
			// 	// console.log("setting and flying to position");
			// 	selectPosition &&
			// 		setPosition([selectPosition.lat, selectPosition.lon]);
			// 	// currentPositionCallback(e.latlng);
			// 	// map.flyTo(e.latlng, map.getZoom());
			// });
			// setCurrentPos(currentPos);
			// currentPositionCallback(currentPos);
		}, []);
		// currentPos && console.log("current pos!", currentPos);

		return position === null ? null : (
			<Marker
				position={position}
				icon={visitorIcon}
				draggable={true}
				eventHandlers={eventHandlers}
			>
				<Popup>You are here</Popup>
			</Marker>
		);
	}

	return (
		<>
			<MapContainer
				// this is LAC Department of Public Works:
				center={[34.084832994, -118.142666096]}
				// this is Paris:
				// center={[48.856614, 2.3522219]}
				zoom={8}
				// scrollWheelZoom
				style={{ height: "100vh" }}
			>
				<LeafletControlGeocoder
					handleGeoCoderDrag={handleGeoCoderDrag}
					currentPositionCallback={currentPositionCallback}
					// autoLocation={autoLocation}
				/>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				<LocationMarker />
				{/* <ClickerMarker /> */}

				<LocationButton />
			</MapContainer>
		</>
	);
};

export default Maps;
